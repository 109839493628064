<template>
    <div class="white--text" style="background-color: black">
        <div class="historia-b1" v-bind:class="{'background-murcielago': bloque1}">
            <v-container>
                <v-row style="height: calc(100vh - 100px)" align="center">
                    <v-col cols="12" md="6">
                        <v-lazy
                            transition="fade-transition"
                            :options="{
                                threshold: .5
                            }"
                            v-model="bloque1"
                        >
                            <div>

                            
                                <h1>El murciélago</h1>
                                <p>El murciélago vuela a traves de la noche. Tiene prisa, pues va a visitar a la flor de maguey, la que solamente abre cuando no hay más sol. Es como él, que prefiere ese momento en la oscuridad para salir. Sigiloso, extrae algo de su néctar. Y así, entre las sombras, vuela de vuelta atravesando campos, satisfecho.</p>
                                <p>En ese ritual de danza entre el murciélago y la flor del maguey, ocurre su polinización. Y con esa misma naturalidad, empieza nuestra historia.</p>

                                <p>Conócela aquí</p>
                            </div>
                        </v-lazy>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container fluid>
            <v-row> 
                <v-col cols="12" class="px-0 py-0 text-center">
                    <v-img src="../assets/img/landscape-historia.jpg" height="62vh" width="100%" class="align-end" style="overflow: visible;">
                        <video src="../assets/img/Intro.mp4" muted autoplay loop style="max-width: 600px; transform: translateY(50%)"></video>
                    </v-img>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col>
                    <br><br><br><br><br><br><br><br><br>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10" class="text-center">
                    <span class="bold" style="font-size: 30px;">Con más de 300 productos seleccionados cuidadosamente por su calidad, procesos de elaboración y certificación, El Murciélago es, desde 2017, un espacio para encontrar, conocer y disfrutar el fascinante mundo del tequila y del mezcal.</span>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="5">
                    <v-img src="../assets/img/agave-historia.jpg"></v-img>
                </v-col>
                <v-col cols="12" md="5">
                    <v-img src="../assets/img/agavecorte-historia.jpg"></v-img>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="10" class="text-center">
                    <span class="bold" style="font-size: 30px;">Una innata curiosidad por el tequila...</span>
                    <p class="text-center">Su origen, formas de elaboración, sus sabores y texturas, sentó las bases para lo que somos hoy. Fue un proceso que se dio de manera natural, motivados por la pasión de conocer más sobre el tequila y el mezcal.</p>
                    <p class="text-center">Cuando el entender del fascinante mundo del tequila y el mezcal no fue suficiente, compartirlo se convirtió en una acción necesaria, empezamos por compartir tragos, botellas y luego, conocimiento en las conversaciones casuales entre amigos, donde el tema principal eran siempre estas bebidas.</p>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6">
                    <v-img src="../assets/img/horno-historia-1.jpg"></v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <v-img src="../assets/img/barril-historia.jpg"></v-img>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12" md="6" class="text-center py-12 px-12">
                    <p class="my-12">Junto con El Murciélago, nace una filosofía que nos mueve:</p>
                    <span class="bold d-block my-12" style="font-size: 30px;">Queremos generar y expandir la cultura del tequila y el mezcal y llegar a toda la República Mexicana, por medio de nuestra tienda en línea.</span>
                    <p class="my-12">Deseamos que más gente se interese, que conozca, pruebe y, al final de cada experiencia, encuentre el tequila o mezcal que más se adecue a su perfil o aquel que lo haga transportarse de forma única al sabor de estas bebidas extraídas de la naturaleza misma. De ahí, estamos seguros, cada uno brindará, platicará e invitará a más personas a encontrar su tequila o mezcal ideal para valorar aquello que es tan nuestro.</p>
                </v-col>
                <v-col cols="12" md="6" class="px-12">
                    <span class="d-block bold" style="font-size: 30px">Misión</span>
                    <p>Generar y extender la cultura del tequila y el mezcal en el norte del país, a través de nuestros productos y conocimiento.</p>

                    <span class="d-block bold" style="font-size: 30px">Visión</span>
                    <p>Estar siempre actualizados en el mundo del tequila y el mezcal. Ser siempre la primera opción para encontrar los mezcales y tequilas de mejor calidad y variedad.</p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: 'Una innata curiosidad por el tequila... | El Murciélago Tequilería',
        // titleTemplate: 'Marisol Malibu',
        htmlAttrs: {
        lang: 'es'
        },
        meta: [
        {name:'description', content: 'Con más de 300 productos seleccionados cuidadosamente, El Murciélago Tequilería es un espacio para conocer y disfrutar el fascinante mundo del tequila y del mezcal.'}
        ]
    },
    data () {
        return {
            bloque1: false
        }
    }
}
</script>